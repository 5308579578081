<template>
	<div style="padding-top: 20px;">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
			hide-required-asterisk="">

			<el-form-item :label="$t('newadd.t.设计点充值')" prop="amount">

				<el-input v-model.number="ruleForm.amount" style="width: 300px;"></el-input>

			</el-form-item>


			<!-- 	<el-form-item >
				<el-radio v-model="radio" label="1">微信</el-radio>
				<el-radio v-model="radio" label="2">支付宝</el-radio>
				<el-radio v-model="radio" label="3">银行卡支付</el-radio>
			</el-form-item> -->

			<!-- 	<div style="margin: 20px 0px;" v-if="radio==3">
				<el-form-item :label="$t('customDesign.cardNumber')" prop="cardNumber" style="margin-bottom:20px;">
					<el-input v-model="ruleForm.cardNumber" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item :label="$t('customDesign.cardPwd')" prop="cardPws">
					<el-input v-model="ruleForm.cardPwd" style="width: 300px;"></el-input>
				</el-form-item>
			</div> -->


			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">
					{{$t("newadd.t.确认充值")}}
				</el-button>
			</el-form-item>

		</el-form>

	</div>


</template>
<script>
	export default {

		data() {
			var checkAmount = (rule, value, callback) => {
				if (value <= 0) {
					callback(new Error('金额不正确'));
					
				}else{
					   callback();
				}
			};
			return {
				radio: "1",
				ruleForm: {
					amount: '',
					// cardNumber: '',
					// cardPws: '',
				},
				rules: {
					amount: [{
							required: true,
							message: this.$t("customDesign.mesRechargeAmount"),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t("customDesign.mesAmountNumber")
						},
						{
							validator: checkAmount,
							trigger: 'blur'
						}
					],
					// cardNumber: [{
					// 	required: true,
					// 	message: this.$t("customDesign.mesCardNumber"),
					// 	trigger: 'blur'
					// }, ],
					// cardPws: [{
					// 	required: true,
					// 	message: this.$t("customDesign.mesCardPwd"),
					// 	trigger: 'blur'
					// }, ],
					// pay: [{
					// 	required: true,
					// 	message: this.$t("customDesign.mesPay"),
					// 	trigger: 'change'
					// }],

				},

			}
		},
		methods: {
			// handleClick(tab, event) {
			// 	console.log(tab, event);
			// },
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$req.post("/client/order/addDesignPoints", {
							  num:this.ruleForm.amount,
							  	onlyCalc: false,
							  	remark: "",
							  	requireInvoice: false
						}).then(async res => {
							this.$message({
								message: "下单成功",
								type: "success",
								onClose: (res) => {
									this.$router.push({
										path: "/orderadmin"
									}, () => {
										location.reload()
									});
								},
							});
						})
					} else {
						return false;
					}
				});
			},
		}
	}
</script>
<style scoped>

</style>
